import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>Nenalezeno</h1>
      <p>Právě jste narazili na stránku, která neexistuje.</p>
    </div>
  </Layout>
)

export default NotFoundPage
